import style from "./button.module.css";

function Button({ onClick }) {
  return (
    <a href="#form" id={style.anchor_form}>
      <button id={style.btn} onClick={onClick}></button>
    </a>
  );
}
export default Button;
