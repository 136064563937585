import React from "react";
import styles from "./Button.module.css";

export default function Button({ title, addStyle, onClick }) {
  return (
    <>
      <button
        className={styles.btn}
        data-style={addStyle}
        data-text={title}
        onClick={onClick}
      >
        {title}
      </button>
    </>
  );
}
